import { RouteComponentProps } from "@reach/router"
import React from "react"
import { Container } from "../../UI/Container"
import { Help } from "../Help/Help"
import { Loader } from "../Loader/Loader"

export const AuthCallback: React.FC<RouteComponentProps> = () => {
  return (
    <>
      <Container className="relative">
        <div className="fixed inset-0 z-40 flex h-full w-full items-center justify-center">
          <div className="text-center">
            <div className="">Waiting for authentication…</div>
            <div className=" text-sm text-slate-500">
              If nothing happens, please contact me through the help center
              below.
            </div>
            <div className="relative mt-8">
              <Loader />
            </div>
          </div>
          <Help forceShow />
        </div>
      </Container>
    </>
  )
}
