import { RouteComponentProps } from "@reach/router"
import React from "react"
import { AuthCallback } from "../../../../components/general/AuthCallback/AuthCallback"

const AuthGoogleCallbackAddSearchConsolePage: React.FC<RouteComponentProps> = (
  props
) => {
  return <AuthCallback />
}

export default AuthGoogleCallbackAddSearchConsolePage
